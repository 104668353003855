<template>
	<div>
		<ul v-if="hasMultipleTabs" class="tabs">
			<li v-for="(tab, index) in tabs" :key="tab.title" @click="selectTab(index)" :class="{'selected': (index == selectedIndex)}">
				{{tab.title}}
			</li>
		</ul>
		<div class="tab">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				selectedIndex: 0,
				tabs: []
			}
		},
		computed: {
			hasMultipleTabs() { // Used to determine whether there is more than one tab in the tab list.
				return (this.tabs.length > 1);
			}
		},
		mounted() {
			this.selectTab(0);
		},
		methods: {
			selectTab(index) {
				this.selectedIndex = index;
				this.tabs.forEach((tab, tabIndex) => {
					tab.isActive = (tabIndex === index);
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	ul.tabs {
		margin:0 0 8px 20px;
		padding:0 0 5px 0;
		border-bottom:2px solid var(--main-accent-color);
		
		li {
			padding:15px 30px;
			border-radius:10px;
			display:inline-block;
			margin-right:5px;
			cursor:pointer;
			
			&.selected {
				font-weight:bold;
				background-color:var(--highlight-color-dark);
				color:var(--main-accent-color);
			}
		}
	}
	
	.tab {
		padding:20px;
	}
</style>