<template>
	<div v-show="isActive" class="tab">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: 'Tab'
			}
		},
		data() {
			return {
				isActive: true
			}
		},
		created() {
			this.$parent.tabs.push(this); // Allows the parent 'Tabs' component to access all of the child tabs.
		}
	}
</script>